import React, { useState } from "react";

import { ErrorMessage, PrimaryButton, SecondaryButton } from "widgets";
import { useAddNote } from "store/backend/assets";

import styles from "./AddNote.module.scss";

const handleOnClickAddNoteButton = (orderId, note, onClose, addNote) => {
  const payload = {
    order_id: orderId,
    note: note,
  };

  addNote(payload, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error("Error adding note to the order:", error);
    },
  });
};

const AddNote = (props) => {
  const { onClose, orderId } = props;
  const [note, setNote] = useState("");

  const {
    mutate: addNote,
    isPending: isAddNotePending,
    error,
    isError,
  } = useAddNote();

  const handleNoteChange = (event) => {
    const value = event.target.value;
    if (value.length <= 80) {
      setNote(value);
    }
  };

  return (
    <div className={styles.popOverContainer}>
      <div className={styles.addNoteTitle}>Add a note for this order (Max 80 characters)</div>
      <textarea
        className={styles.textarea}
        value={note}
        onChange={handleNoteChange}
        maxLength={80}
      />
      <div className={styles.charCount}>
        {note.length}/80 characters
      </div>
      <div className={styles.flexContainer}>
        <SecondaryButton onClick={onClose}>Go Back</SecondaryButton>
        <PrimaryButton
          onClick={() =>
            handleOnClickAddNoteButton(orderId, note, onClose, addNote)
          }
          disabled={!note}
        >
          {isAddNotePending ? "Submitting" : "Submit"}
        </PrimaryButton>
      </div>
      {isError ? (
        <ErrorMessage>{error.message || "Error adding note"}</ErrorMessage>
      ) : null}
    </div>
  );
};

export default AddNote;
