import { useMutation } from "@tanstack/react-query";
import useAuth from "../../hooks/useAuth";
import {
  ISD_CODE,
  LOGIN_ENDPOINT,
  LOGIN_PRODUCT_ID,
  LOGIN_TENANT_ID,
  PLATFORM,
  REQUEST_PRODUCT_ID,
  VALID_SALES_ROLE_IDS,
} from "constants/config";

const login = async ({ mobile, password }) => {
  const response = await fetch(LOGIN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      platform: PLATFORM,
      "product-id": LOGIN_PRODUCT_ID,
      "tenant-id": LOGIN_TENANT_ID,
    },
    body: JSON.stringify({
      admission_number: mobile,
      isd_code: ISD_CODE,
      phone: mobile,
      password: password,
      product_id: REQUEST_PRODUCT_ID,
      tenant_id: LOGIN_TENANT_ID,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.detail || "Unable to log in";
    throw new Error(errorMessage);
  }

  return response.json();
};

export const useLogin = (onSuccess) => {
  const { login: authLogin } = useAuth();

  const mutation = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data.accessToken) {
        if (data.roles && data.roles.some(roleId => Object.keys(VALID_SALES_ROLE_IDS).map(Number).includes(roleId))) {
          authLogin(data.accessToken);
          onSuccess(data);
        } else {
          throw new Error(
            "User does not have the required permissions to log in."
          );
        }
      } else {
        throw new Error("No access token in response");
      }
    },
    onError: (error) => {
      console.error("Login error:", error.message || "An error occurred");
    },
  });

  return { ...mutation };
};
