import React, { useMemo } from "react";

import { ErrorMessage, Text, Textfield } from "widgets";
import Loader from "Shared/Loader/Loader";
import { useFetchPostalCodeDetails } from "store/backend/assets";

import styles from "./AddressForm.module.scss";

const generateFieldProps = (addressType, field) => ({
  id: `${addressType}-${field}`,
  name: `${addressType}[${field}]`,
});

const handlePhoneNumberChange = (event, handleChangeFields) => {
  const newValue = event.target.value;
  if (newValue.length <= 10) {
    handleChangeFields("recipientPhoneNumber", newValue);
  }
};

const AddressFields = (props) => {
  const {
    address,
    handleChangeFields,
    disabled,
    errors,
    handleBlur,
    addressType,
  } = props;

  const {
    isFetching: isPostalCodeDetailsFetching,
    isPending: isPostalCodeDetailsLoading,
    data: postalCodeDetails,
    isError,
    error,
  } = useFetchPostalCodeDetails(address?.pincode);

  const { district, state } = useMemo(() => {
    if (postalCodeDetails && postalCodeDetails.district) {
      const postalCode = postalCodeDetails.postal_code;
      const district = postalCodeDetails.district;
      const state = postalCodeDetails.state;
      if (postalCode.id !== address.pincodeId) {
        handleChangeFields("pincodeId", postalCode.id);
      }
      if (state.id !== address.stateId) {
        handleChangeFields("stateId", state.id);
        handleChangeFields("state", state.name);

      }
      if (district.id !== address.districtId) {
        handleChangeFields("districtId", district.id);
        handleChangeFields("district", district.name);

      }
      return {
        district,
        state,
        postalCode,
      };
    }
    return {
      district: { id: "", name: "" },
      state: { id: "", name: "" },
      postalCode: { id: "", name: "" },
    };
  }, [postalCodeDetails, handleChangeFields, address]);


  return (
    <div className={styles.formGrid}>
      <div className={styles.textField}>
        <Text>Recipient Name*</Text>
        <Textfield
          {...generateFieldProps(addressType, "recipientName")}
          value={address?.recipientName || ""}
          handleOnChange={(event) =>
            handleChangeFields("recipientName", event.target.value)
          }
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.recipientName &&
          (
            <ErrorMessage>
              {errors[`${addressType}Address`].recipientName}
            </ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>Recipient Phone Number*</Text>
        <Textfield
          {...generateFieldProps(addressType, "recipientPhoneNumber")}
          value={address?.recipientPhoneNumber || ""}
          handleOnChange={(event) =>
            handlePhoneNumberChange(event, handleChangeFields)
          }
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.recipientPhoneNumber && (
            <ErrorMessage>
              {errors[`${addressType}Address`].recipientPhoneNumber}
            </ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>Address Line 1*</Text>
        <Textfield
          {...generateFieldProps(addressType, "addressLine1")}
          value={address?.addressLine1 || ""}
          handleOnChange={(event) =>
            handleChangeFields("addressLine1", event.target.value)
          }
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.addressLine1 &&
       (
            <ErrorMessage>
              {errors[`${addressType}Address`].addressLine1}
            </ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>Address Line 2</Text>
        <Textfield
          {...generateFieldProps(addressType, "addressLine2")}
          value={address?.addressLine2 || ""}
          handleOnChange={(event) =>
            handleChangeFields("addressLine2", event.target.value)
          }
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.addressLine2 &&
          (
            <ErrorMessage>
              {errors[`${addressType}Address`].addressLine2}
            </ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>Street</Text>
        <Textfield
          {...generateFieldProps(addressType, "street")}
          value={address?.street || ""}
          handleOnChange={(event) =>
            handleChangeFields("street", event.target.value)
          }
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.street &&
          (
            <ErrorMessage>
              {errors[`${addressType}Address`].street}
            </ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>City*</Text>
        <Textfield
          {...generateFieldProps(addressType, "city")}
          value={address?.city || ""}
          handleOnChange={(event) =>
            handleChangeFields("city", event.target.value)
          }
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.city &&
         (
            <ErrorMessage>{errors[`${addressType}Address`].city}</ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>Pincode*</Text>
        <Textfield
          {...generateFieldProps(addressType, "pincode")}
          value={address?.pincode || ""}
          handleOnChange={(event) => {
            handleChangeFields("pincode", event.target.value);
          }}
          disabled={disabled}
          onBlur={handleBlur}
        />
        {errors?.[`${addressType}Address`]?.pincode &&
          (
            <ErrorMessage>
              {errors[`${addressType}Address`].pincode}
            </ErrorMessage>
          )}
        {isError ? (
          <ErrorMessage>
            { error.message || "Error fetching pincode details"}
          </ErrorMessage>
        ) : null}
      </div>
      <div className={styles.textField}>
        <Text>District*</Text>
        {isPostalCodeDetailsLoading && isPostalCodeDetailsFetching  ? (
          <Loader />
        ) : (
          <Textfield
            {...generateFieldProps(addressType, "district")}
            value={district.name}
            disabled={true}
            onBlur={handleBlur}
          />
        )}
        {errors?.[`${addressType}Address`]?.district &&
         (
            <ErrorMessage>
              {errors[`${addressType}Address`].district}
            </ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>State*</Text>
        {isPostalCodeDetailsLoading && isPostalCodeDetailsFetching ? (
          <Loader />
        ) : (
          <Textfield
            {...generateFieldProps(addressType, "state")}
            value={state.name}
            disabled={true}
            onBlur={handleBlur}
          />
        )}
        {errors?.[`${addressType}Address`]?.state &&(
            <ErrorMessage>{errors[`${addressType}Address`].state}</ErrorMessage>
          )}
      </div>
      <div className={styles.textField}>
        <Text>Country*</Text>
        <Textfield
          {...generateFieldProps(addressType, "country")}
          value={"INDIA"}
          disabled={true}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default AddressFields;
