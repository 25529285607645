import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";

const StyledTable = styled(Table)(() => ({
  "& th": {
    backgroundColor: "#007bff",
    color: "#ffffff",
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "nowrap",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
}));

const AccordionTableWrapper = styled("div")(() => ({
  backgroundColor: "#d9d9d9",
  padding: "10px", 
}));

const CustomTable = ({ columns, rows, accordion = false, accordionTableColumns }) => {
  const [openRow, setOpenRow] = useState({});

  const toggleRow = (rowIndex) => {
    setOpenRow((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="dynamic table">
        <TableHead>
          <TableRow>
            {accordion && <TableCell />}
            {columns.map((column) => (
              <TableCell key={column.label} align="center">
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow>
                {accordion && (
                  <TableCell>
                    <IconButton onClick={() => toggleRow(rowIndex)} size="small">
                      {openRow[rowIndex] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell key={column.field} align={column.align || "left"}>
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
              {accordion && openRow[rowIndex] && row.history && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <AccordionTableWrapper>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {accordionTableColumns.map((col) => (
                              <TableCell key={col.label}>{col.label}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.history.map((historyRow, index) => (
                            <TableRow key={index}>
                              {accordionTableColumns.map((col) => (
                                <TableCell key={col.field}>
                                  {historyRow[col.field]}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionTableWrapper>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default CustomTable;
