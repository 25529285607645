import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { Select } from "widgets";
import CustomMenuItem from "widgets/MenuItem/CustomMenuItem";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const handleMenuItemClick = (value, itemValue, handleChange) => {
  const newValue = value.includes(itemValue)
    ? value.filter((val) => val !== itemValue)
    : [...value, itemValue];
  handleChange({ target: { value: newValue } });
};

const MultipleSelect = (props) => {
  const { id, name, value, menuItems, tag, handleChange } = props;

  return (
    <div>
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel
          sx={{ backgroundColor: "white", fontWeight: "400" }}
          id={id}
        >
          {tag}
        </InputLabel>
        <Select
          id={id}
          name={name}
          multiple
          value={value}
          onChange={(e) => handleChange(e)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{tag}</em>;
            }
            return selected.join(", ");
          }}
          MenuProps={MenuProps}
        >
          {menuItems.map((menuItem) => (
            <CustomMenuItem
              key={menuItem.key}
              value={menuItem.value}
              onClick={() =>
                handleMenuItemClick(value, menuItem.value, handleChange)
              }
            >
              <Checkbox checked={value.includes(menuItem.value)} />
              {menuItem.label}
            </CustomMenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;
