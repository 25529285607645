import * as React from "react";
import card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/system";
import styles from "./Card.module.scss";

const StyledCard = styled(card)(({ height }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FFFFFF",
  color: "#FFFFFF",
  height: height || "auto",
  width: "100%",
  ":hover": {
    backgroundColor: "#dedede",
  },
  margin: "10px",
  border: "3px solid #929292",

  "@media (max-width: 768px)": {
    height: "auto",
    padding: "8px",
    margin: "8px",
  },

  "@media (max-width: 480px)": {
    padding: "5px",
    margin: "5px",
  },
}));

export default function Card({ name, onClick, children, height }) {
  return (
    <StyledCard onClick={onClick} height={height}>
      <CardContent className={styles.Content}>
        {children}
        {name}
      </CardContent>
    </StyledCard>
  );
}
