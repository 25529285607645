import React from "react";
import { styled } from "@mui/system";

const StyledHeading = styled("div")(() => ({
  fontSize: "20px",
  fontWeight: "500",
  textAlign: "left",
  color: "#000000",
  whiteSpace: "nowrap",
}));

const Text = ({ heading, children }) => (
  <StyledHeading>
    <div>{heading}</div>
    <div>{children}</div>
  </StyledHeading>
);
export default Text;
