import React from "react";
import { Switch, FormControlLabel } from "@mui/material";

const Toggle = ({ checked, onChange, label }) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} color="primary" />}
      label={label}
    />
  );
};

export default Toggle;
