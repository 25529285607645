import { styled } from "@mui/system";
import { Button } from "@mui/material";

const PrimaryButton = styled(Button)`
  && {
    background: #007bff;
    color: #ffffff;
    border-radius: 4px;
    box-shadow: none;
    text-transform: capitalize;
    min-width: 6rem;
    width: fit-content;
    padding: 0.5rem 0.9rem;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #e0e0e0;
      color: #a0a0a0;
    }
  }
`;

export default PrimaryButton;
