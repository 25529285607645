import React from "react";
import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCheckbox = styled(Checkbox)(() => ({}));

const CheckBox = ({ checked, handleCheck, title }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <StyledCheckbox checked={checked} onChange={handleCheck} />
    <span
      style={{
        marginLeft: "2px",
      }}
    >
      {title}
    </span>
  </div>
);
export default CheckBox;
