import React from "react";

import { Text } from "widgets";

import styles from "./DisplayOrder.module.scss";
import { INR_SYMBOL } from "constants/config";

const DisplayOrder = (props) => {
  const { selectedPackageSubscriptions } = props;
  return (
    <div className={styles.orderSummaryContainer}>
      <Text>Selected Package Subscriptions</Text>
      {selectedPackageSubscriptions &&
      selectedPackageSubscriptions.length > 0 ? (
        selectedPackageSubscriptions.map((pkg) => (
          <>
            {pkg.subscriptions.map((subscription) => (
              <div key={subscription.id} className={styles.orderSummaryBox}>
                <div className={styles.label}>
                  <span className={styles.detailTitle}>Package Name:</span>
                  <span>{pkg.display_name}</span>
                </div>
                <div className={styles.label}>
                  <span className={styles.detailTitle}>Plan Name:</span>
                  <span>{subscription.plan_category.name}</span>
                </div>
                <div className={styles.label}>
                  <span className={styles.detailTitle}>Validity:</span>
                  <span>{pkg.validity_date}</span>
                </div>
                <div className={styles.label}>
                  <span className={styles.detailTitle}>MRP:</span>
                  <span>{INR_SYMBOL + subscription.mrp}</span>
                </div>
              </div>
            ))}
          </>
        ))
      ) : (
        <Text>No packages selected.</Text>
      )}
    </div>
  );
};

export default DisplayOrder;
