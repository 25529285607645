import React from "react";

import { Table } from "widgets";

import styles from "./PaymentDetails.module.scss";
import { INR_SYMBOL } from "constants/config";

const paymentDetailsColumn = [
  { label: "Amount", field: "amount" },
  { label: "Status", field: "status" },
  { label: "Payment Date", field: "paymentDate" },
  { label: "Payment Method", field: "paymentMethod" },
];

const getPaymentDetailsRows = (paymentDetails = []) => {
  return paymentDetails
    .sort((p1, p2) => new Date(p2.date) - new Date(p1.date))
    .map((payment) => ({
      amount: INR_SYMBOL + payment.amount,
      status: payment.status?.name,
      paymentDate: new Date(payment.date).toLocaleString(),
      paymentMethod: payment.method?.name,
    }));
};

const PaymentDetails = (props) => {
  const { paymentDetails } = props;
  const rows = paymentDetails ? getPaymentDetailsRows(paymentDetails) : [];

  return (
    <div className={styles.paymentDetailsTable}>
      <Table columns={paymentDetailsColumn} rows={rows} />
    </div>
  );
};

export default PaymentDetails;
