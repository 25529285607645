import React from "react";
import { styled } from "@mui/system";

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "flex-start",
  flexWrap: "wrap",
  margin: "8px 0",
  width: "100%",
  minWidth: "0", 
});

const Title = styled("span")(({ fontSize }) => ({
  fontFamily: "Arial, sans-serif",
  fontWeight: "bold",
  fontSize: fontSize || "16px",
  whiteSpace: "nowrap",
  marginRight: "8px",
  flexShrink: 0,
  color: "#333",
  minWidth: "fit-content",
}));

const Value = styled("span")(({ fontSize }) => ({
  fontFamily: "Courier New, monospace",
  fontSize: fontSize || "16px",
  color: "#555",
  wordWrap: "break-word",
  minWidth: "0", 
}));

const Detail = ({ title, value, fontSize }) => (
  <Wrapper>
    <Title fontSize={fontSize}>{title}:</Title>
    <Value fontSize={fontSize}>{value}</Value>
  </Wrapper>
);

export default Detail;
