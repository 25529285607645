import React, { useState } from "react";

import {
  ErrorMessage,
  PrimaryButton,
  SecondaryButton,
  SingleSelect,
  Text,
} from "widgets";
import Loader from "Shared/Loader/Loader";
import {
  useOrderCancelReasons,
  useRequestCancelOrder,
} from "store/backend/assets";

import styles from "./CancelOrder.module.scss";

const handleCancelOrderReasonChange = (event, setSelectedReason) => {
  setSelectedReason(event.target.value);
};

const handleSubmitCancellationReason = (
  selectedReason,
  orderId,
  requestCancelOrder,
  onClose
) => {
  if (!selectedReason) {
    return;
  }

  const payload = {
    order_id: orderId,
    reason_id: selectedReason,
  };

  requestCancelOrder(payload, {
    onSuccess: () => {
      onClose();
      window.location.reload();
    },
    onError: (error) => {
      console.error("Error cancelling order:", error);
    },
  });
};

const CancelOrder = (props) => {
  const { onClose, orderId } = props;
  const [selectedReason, setSelectedReason] = useState(null);
  const {
    mutate: requestCancelOrder,
    isPending: isRequestCancelOrderPending,
    error,
    isError,
  } = useRequestCancelOrder();
  const {
    isPending: isReasonsLoading,
    data: reasons,
    error: reasonError,
  } = useOrderCancelReasons();


  if (reasonError) {
    return <span>Error fetching Cancellation Reasons</span>;
  }

  return (
    <div className={styles.popOverContainer}>
      <Text>Please Select Reason for Cancellation</Text>
      <div className={styles.dropdownContainer}>
        {isReasonsLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <SingleSelect
            name="Order Cancel Reason"
            handleChange={(event) =>
              handleCancelOrderReasonChange(event, setSelectedReason)
            }
            menuItems={reasons}
            defaultSelected="Select a Reason"
          />
        )}
      </div>
      <div className={styles.flexContainer}>
        <SecondaryButton onClick={onClose}>Go Back</SecondaryButton>
        <PrimaryButton
          onClick={() =>
            handleSubmitCancellationReason(
              selectedReason,
              orderId,
              requestCancelOrder,
              onClose
            )
          }
          disabled={!selectedReason || isRequestCancelOrderPending}
        >
          {isRequestCancelOrderPending ? "Submitting..." : "Submit"}
        </PrimaryButton>
      </div>
      {isError && (
        <div className={styles.errorMessage}>
          <ErrorMessage>
            {error?.message ||
              "An error occurred while requesting order cancellation."}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default CancelOrder;
