import { SECOND } from "constants/queryCacheTimes";
import Cookies from "js-cookie";

const isValidUser = () => {
  const token = Cookies.get("jwtToken");

  if (!token) {
    return false;
  }

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const isExpired = payload.exp && Date.now() >= payload.exp * SECOND;
    return !isExpired;
  } catch (error) {
    return false;
  }
};

export { isValidUser };
