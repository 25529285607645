import React from "react";

import { DetailDisplay, Table } from "widgets";

import styles from "./ReviewTransferOrder.module.scss";
import { reduceToFixedDecimals } from "utils/amountCalculation";

const getAdjustedPayment = (amountPaidInPreviousOrder, newOrderAmount) => {
  if (amountPaidInPreviousOrder > newOrderAmount) {
    return {
      adjustedPayment: newOrderAmount,
      remainingPaymentToRefund: amountPaidInPreviousOrder - newOrderAmount,
      remainingPaymentToCollect: 0,
    };
  } else if (amountPaidInPreviousOrder < newOrderAmount) {
    return {
      adjustedPayment: amountPaidInPreviousOrder,
      remainingPaymentToRefund: 0,
      remainingPaymentToCollect: newOrderAmount - amountPaidInPreviousOrder,
    };
  } else {
    return {
      adjustedPayment: newOrderAmount,
      remainingPaymentToRefund: 0,
      remainingPaymentToCollect: 0,
    };
  }
};

const ReviewTransferOrder = (props) => {
  const {
    transferOrderDetails,
    billingAddress,
    selectedPackageSubscriptions,
    discountDetails,
    newOrderAmount,
  } = props;

  const amountPaidInPreviousOrder =
    transferOrderDetails && transferOrderDetails.amountPaid;
  const {
    adjustedPayment,
    remainingPaymentToRefund,
    remainingPaymentToCollect,
  } = getAdjustedPayment(amountPaidInPreviousOrder, newOrderAmount);

    const newDiscountDetails = discountDetails
    ? `${discountDetails.coupon_code ? `Coupon Code: ${discountDetails.coupon_code}` : ""}
       ${discountDetails.discount_value ? (discountDetails.coupon_code ? " | " : "") + `Discount Value: ${discountDetails.is_percentage ? discountDetails.discount_value + "%" : "₹" + discountDetails.discount_value}` : ""}`.trim()
    : "-";

  const oldDiscountDetails = transferOrderDetails.coupon
    ? `${transferOrderDetails.coupon.code ? `Coupon Code: ${transferOrderDetails.coupon.code}` : ""}
       ${transferOrderDetails.coupon.value ? (transferOrderDetails.coupon.code ? " | " : "") + `Discount Value: ₹${transferOrderDetails.coupon.value}` : ""}`.trim()
    : "-";

    const newPackageNames = selectedPackageSubscriptions
      .map((pkg) => pkg.displayName)
      .join(", ");
    const previousPackageNames = transferOrderDetails.packages
      .map((pkg) => pkg.displayName)
      .join(", ");

    const formattedNewBillingAddress = `
    ${billingAddress.recipientName},
    ${billingAddress.addressLine1},
    ${billingAddress.city},
    ${billingAddress.district},
    ${billingAddress.state},
    ${billingAddress.pincode},
  `;

  const previousBillingAddress = transferOrderDetails.addressDetails.billingAddress;

  const formattedOldBillingAddress = `
    ${previousBillingAddress.recipientName},
    ${previousBillingAddress.addressLine1},
    ${previousBillingAddress.street ? previousBillingAddress.street + ',' : ''}
    ${previousBillingAddress.addressLine2 ? previousBillingAddress.addressLine2 + ',' : ''}
    ${previousBillingAddress.city},
    ${previousBillingAddress.district},
    ${previousBillingAddress.state},
    ${previousBillingAddress.postalCode},
    ${previousBillingAddress.country},
  `;

    const columns = [
      { label: "Field", field: "field", align: "center" },
      { label: "Previous Value", field: "oldValue", align: "center" },
      { label: "New Value", field: "newValue", align: "center" },
    ];

    const rows = [
      {
        field: "Billing Address",
        oldValue: formattedOldBillingAddress,
        newValue: formattedNewBillingAddress,
      },
      {
        field: "Package",
        oldValue: previousPackageNames,
        newValue: newPackageNames,
      },
      {
        field: "Discount Code",
        oldValue: oldDiscountDetails,
        newValue: newDiscountDetails,
      },
      {
        field: "Order Amount",
        oldValue: transferOrderDetails.amount || "-",
        newValue: newOrderAmount,
      },
    ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.displayOrderId}>
        <span className={styles.title}>Previous Order Id :</span>
        <span className={styles.value}>{transferOrderDetails.orderId}</span>
      </div>
      <Table columns={columns} rows={rows} />
      <div className={styles.paymentDetails}>
        <DetailDisplay title="Adjusted Payment" value={reduceToFixedDecimals(adjustedPayment)} />
        <DetailDisplay
          title="Remaining payment to refund"
          value={reduceToFixedDecimals(remainingPaymentToRefund)}
        />
        <DetailDisplay
          title="Remaining payment to collect"
          value={reduceToFixedDecimals(remainingPaymentToCollect)}
        />
      </div>
    </div>
  );
};

export default ReviewTransferOrder;
