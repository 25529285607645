import { QueryCache, MutationCache, QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { removeOldestQuery } from "@tanstack/react-query-persist-client";
import { HOUR, QUERY_RETRY_ATTEMPTS } from "../constants/queryCacheTimes";

import { logger } from "../utils/logger";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 12 * HOUR,
      retry: QUERY_RETRY_ATTEMPTS,
    },
  },
  queryCache: new QueryCache({
    onError: logger,
  }),
  mutationCache: new MutationCache({
    onError: logger,
  }),
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  retry: removeOldestQuery,
});

export { queryClient, persister };
