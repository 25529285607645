import React from "react";

import {
  CheckBox,
  PrimaryButton,
  SecondaryButton,
  MultipleSelect,
  SearchField,
  ErrorMessage,
} from "widgets";
import { useFetchPackages, GRADES, TARGET_EXAMS } from "store/backend/assets";
import Loader from "Shared/Loader/Loader";
import SelectedPackageSubscriptions from "components/OrderSummary/SelectedPackageSubscriptions";

import styles from "./PackageSubscription.module.scss";
import { INR_SYMBOL } from "constants/config";

const handlePackageSelection = (
  packageSubscription,
  selectedPackageSubscriptions,
  onPackagesSelected
) => {
  if (
    selectedPackageSubscriptions.some((p) => p.id === packageSubscription.id)
  ) {
    const updatedSelections = selectedPackageSubscriptions.filter(
      (p) => p.id !== packageSubscription.id
    );
    onPackagesSelected(updatedSelections);
  } else {
    const updatedSelections = [
      ...selectedPackageSubscriptions,
      packageSubscription,
    ];
    onPackagesSelected(updatedSelections);
  }
};

const handleOnClickRefreshButton = (onFilterChange) => {
  onFilterChange("selectedGrade", []);
  onFilterChange("selectedTargetExam", []);
};

const handleOnClickSearchButton = (refetch) => {
  refetch();
};

const isSearchButtonEnabled = (selectedTargetExam, selectedGrade) =>
  selectedTargetExam.length > 0 && selectedGrade.length > 0;

const renderPackageDetails = (
  packageSubscription,
  selectedPackageSubscriptions,
  onPackagesSelected
) => {
  return (
    <div key={packageSubscription.id} className={styles.packageDetailBox}>
      <CheckBox
        className={styles.packageCheckBox}
        checked={selectedPackageSubscriptions.some(
          (p) => p.id === packageSubscription.id
        )}
        handleCheck={() =>
          handlePackageSelection(
            packageSubscription,
            selectedPackageSubscriptions,
            onPackagesSelected
          )
        }
      />
      <div className={styles.packageDetailContent}>
        <div className={styles.label}>
          <span className={styles.detailTitle}>Package Name:</span>
          <span>{packageSubscription.displayName}</span>
        </div>
        <div className={styles.label}>
          <span className={styles.detailTitle}>Plan Name:</span>
          <span>{packageSubscription.planName}</span>
        </div>
        <div className={styles.label}>
          <span className={styles.detailTitle}>Validity:</span>
          <span>{packageSubscription.planValidity}</span>
        </div>
        <div className={styles.mrp}>
          <span className={styles.detailTitle}>MRP:</span>
          <span>{INR_SYMBOL + packageSubscription.mrp}</span>
        </div>
      </div>
    </div>
  );
};

const PackageSubscription = ({
  selectedPackageSubscriptions,
  onPackagesSelected,
  filters,
  searchTerm,
  onFilterChange,
  onSearchTermChange,
  handleChangePackageSubscriptionSelection,
}) => {
  const { selectedGrade, selectedTargetExam } = filters;

  const appliedFilters = {
    exams: selectedTargetExam,
    grade: selectedGrade,
  };

  const {
    isFetching,
    isPending: isPackageDetailsLoading,
    data: packageDetails,
    error: packageDetailsError,
    refetch,
  } = useFetchPackages(appliedFilters, false);

  const filteredPackages = !!packageDetails
    ? packageDetails.filter((pkg) =>
        pkg.displayName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.packageContainer}>
        <div className={styles.dropdownContainer}>
          <div className={styles.gradesDropdown}>
            <MultipleSelect
              multiple
              name="grade"
              tag="Select Grade"
              value={selectedGrade}
              menuItems={GRADES}
              handleChange={(event) =>
                onFilterChange("selectedGrade", event.target.value)
              }
              showCheckbox={true}
            />
          </div>
          <div className={styles.targetExamDropdown}>
            <MultipleSelect
              multiple
              name="targetExam"
              tag="Select Target Exam"
              value={selectedTargetExam}
              menuItems={TARGET_EXAMS}
              handleChange={(event) =>
                onFilterChange("selectedTargetExam", event.target.value)
              }
              showCheckbox={true}
            />
          </div>
          <div className={styles.packageDetailsButtonContainer}>
            <PrimaryButton
              disabled={
                !isSearchButtonEnabled(selectedTargetExam, selectedGrade)
              }
              onClick={() => handleOnClickSearchButton(refetch)}
            >
              Search
            </PrimaryButton>
            <SecondaryButton
              onClick={() => handleOnClickRefreshButton(onFilterChange)}
            >
              Clear
            </SecondaryButton>
          </div>
        </div>
        <div className={styles.searchFieldContainer}>
          <SearchField
            placeholder="Search by package name"
            value={searchTerm}
            onChange={(e) => onSearchTermChange(e.target.value)}
          />
        </div>
        <div className={styles.packageDetailsContainer}>
          {isPackageDetailsLoading && isFetching ? (
            <Loader />
          ) : (
            <>
              {packageDetailsError && (
                <ErrorMessage>
                  Error: {packageDetailsError.message}
                </ErrorMessage>
              )}
              {filteredPackages.length > 0 ? (
                filteredPackages.map((packageSubscription) =>
                  renderPackageDetails(
                    packageSubscription,
                    selectedPackageSubscriptions,
                    onPackagesSelected
                  )
                )
              ) : packageDetails && packageDetails.length === 0 ? (
                <ErrorMessage>No Packages found</ErrorMessage>
              ) : (
                <p>Select filters to search Package Subscriptions.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.selectedPackageSubscriptionsContainer}>
        <SelectedPackageSubscriptions
          selectedPackageSubscriptions={selectedPackageSubscriptions}
          handleChangePackageSubscriptionSelection={
            handleChangePackageSubscriptionSelection
          }
        />
      </div>
    </div>
  );
};

export default PackageSubscription;
