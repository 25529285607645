import React, { useEffect, useState } from "react";

import {
  Dialog,
  ErrorMessage,
  PrimaryButton,
  SingleSelect,
  Text,
  Textfield,
} from "widgets";
import {
  useFetchLoanProviderDetails,
  usePunchPayment,
} from "store/backend/assets";

import styles from "./LoanPayment.module.scss";
import Loader from "Shared/Loader/Loader";
import { inRange, isEmpty } from "lodash";
import { reduceToFixedDecimals } from "utils/amountCalculation";
import OnlinePayment from "components/OnlinePayment/OnlinePayment";
import { ONE_RUPEE } from "constants/config";

const isLoanAmountInValidRange = (loanAmount, selectedTenure) => {
  const minimumLoanAmountProviderAllows = !selectedTenure.minimum_loan_amount
    ? 0
    : selectedTenure.minimum_loan_amount;
  const maximumLoanAmountProviderAllows = !selectedTenure.maximum_loan_amount
    ? Number.MAX_SAFE_INTEGER
    : selectedTenure.maximum_loan_amount;
  return inRange(
    loanAmount,
    minimumLoanAmountProviderAllows,
    maximumLoanAmountProviderAllows + 1
  );
};

const getLoanProvider = (loanProviderDetails) => {
  return loanProviderDetails.map((provider) => {
    return {
      id: provider.id,
      value: provider.id,
      label: provider.provider,
    };
  });
};

const getTenureOptions = (tenureOptions) => {
  return tenureOptions.map((tenure) => {
    return { ...tenure, label: tenure.tenure_in_months, value: tenure };
  });
};

const handleTenureChange = (
  event,
  setSelectedTenure,
  setLoanApplicationNumber,
  setDownpayment,
  setMonthlyEMI,
  setLoanAmountErrorLogic
) => {
  setSelectedTenure(event.target.value);
  setLoanApplicationNumber("");
  setDownpayment(null);
  setMonthlyEMI("");
  setLoanAmountErrorLogic("");
};

const handleProviderChange = (
  event,
  setSelectedProviderId,
  setTenureOptions,
  setSelectedTenure,
  loanProviderDetails,
  setLoanApplicationNumber,
  setDownpayment,
  setMonthlyEMI,
  setLoanAmountErrorLogic
) => {
  const providerId = event.target.value;
  setSelectedProviderId(providerId);
  const selectedProvider = loanProviderDetails.find(
    (provider) => provider.id === providerId
  );
  if (selectedProvider) {
    setTenureOptions(selectedProvider.emi_divisions);
    setSelectedTenure(null);
    setLoanApplicationNumber("");
    setDownpayment(null);
    setMonthlyEMI("");
    setLoanAmountErrorLogic("");
  }
};

const handlePunchPayment = (
  selected_order_id,
  pendingAmount,
  selectedProviderId,
  selectedTenure,
  loanApplicationNumber,
  punchPayment,
  setDialogContent,
  setDialogOpen
) => {
  const payload = {
    order_id: selected_order_id,
    amount: reduceToFixedDecimals(pendingAmount),
    currency_id: 1,
    payment_details: {
      loan_id: loanApplicationNumber.trim(),
      loan_provider_id: selectedProviderId,
      tenure_in_months: selectedTenure.tenure_in_months,
      payment_mode: "LOAN",
    },
  };
  punchPayment(payload, {
    onSuccess: (response) => {
      const successMessage = `
      ${"Loan Payment Successfully punched."}\n
      Payment ID: ${response.payment_id}\n
      Loan ID: ${response.payment_details.loan_id}
    `;
      setDialogContent(successMessage);
      setDialogOpen(true);
    },
  });
};
const isFormValid = (
  selectedProviderId,
  selectedTenure,
  loanApplicationNumber
) => {
  return (
    selectedProviderId !== null &&
    selectedTenure !== "" &&
    loanApplicationNumber.trim() !== ""
  );
};

const handleDialogClose = (setDialogOpen) => setDialogOpen(false);

const LoanPayment = (props) => {
  const { totalAmount, pendingAmount, selected_order_id } = props;
  const { isPending: isLoanProviderDetailLoading, data: loanProviderDetails } =
    useFetchLoanProviderDetails();
  const {
    mutate: punchPayment,
    isPending: isPunchPaymentLoading,
    isError,
    error,
  } = usePunchPayment();
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [tenureOptions, setTenureOptions] = useState([]);
  const [selectedTenure, setSelectedTenure] = useState(null);
  const [loanApplicationNumber, setLoanApplicationNumber] = useState("");
  const [downpayment, setDownpayment] = useState(null);
  const [monthlyEMI, setMonthlyEMI] = useState("");
  const [loanAmountErrorLogic, setLoanAmountErrorLogic] = useState("");
  const [
    remainingDownPaymentAmountForProvider,
    setRemainingDownPaymentAmountForProvider,
  ] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const loanAmount = reduceToFixedDecimals(totalAmount);

  const isPaymentMoreThanDownPayment =
    totalAmount - pendingAmount > downpayment;

  useEffect(() => {
    if (dialogContent.length > 0 && dialogOpen === false)
      window.location.reload();
  }, [dialogOpen]);

  useEffect(() => {
    if (isEmpty(selectedTenure)) {
      return;
    }
    const dpAmount = reduceToFixedDecimals(
      ((loanAmount * selectedTenure.downpayment_in_percentage) / 100)
    );
    setDownpayment(dpAmount);
    const totalEMIamount = loanAmount - dpAmount;
    const monthlyEMI = reduceToFixedDecimals(
      (totalEMIamount / selectedTenure.total_emi)
    );
    setMonthlyEMI(monthlyEMI);
    const minimumLoanAmountProviderAllows = !selectedTenure.minimum_loan_amount
      ? 0
      : selectedTenure.minimum_loan_amount;
    const maximumLoanAmountProviderAllows = !selectedTenure.maximum_loan_amount
      ? Number.MAX_SAFE_INTEGER
      : selectedTenure.maximum_loan_amount;

    if (loanAmount > maximumLoanAmountProviderAllows) {
      setLoanAmountErrorLogic(
        "Loan amount exceeds maximum loan amount for provider"
      );
    } else if (loanAmount < minimumLoanAmountProviderAllows) {
      setLoanAmountErrorLogic(
        "Loan amount less than minimum loan amount for provider"
      );
    } else if (
      Math.abs(dpAmount - reduceToFixedDecimals(totalAmount - pendingAmount)) >
      ONE_RUPEE
    ) {
      const remainingDownPaymentAmountForProvider = reduceToFixedDecimals(
        dpAmount - totalAmount + pendingAmount
      );
      if (remainingDownPaymentAmountForProvider > 0) {
        setLoanAmountErrorLogic(
          `Please pay more Rs ${remainingDownPaymentAmountForProvider} downpayment amount to continue`
        );
        setRemainingDownPaymentAmountForProvider(
          remainingDownPaymentAmountForProvider
        );
      } else {
        setLoanAmountErrorLogic(
          `The required down payment was Rs ${dpAmount}, but an excess of Rs ${Math.abs(
            remainingDownPaymentAmountForProvider
          )} has been paid.`
        );
      }
    } else {
      setLoanAmountErrorLogic("");
    }
  }, [loanAmount, selectedTenure]);

  if (isLoanProviderDetailLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.loanPaymentContainer}>
        <Text>Select Loan Provider</Text>
        <SingleSelect
          menuItems={getLoanProvider(loanProviderDetails)}
          handleChange={(event) =>
            handleProviderChange(
              event,
              setSelectedProviderId,
              setTenureOptions,
              setSelectedTenure,
              loanProviderDetails,
              setLoanApplicationNumber,
              setDownpayment,
              setMonthlyEMI,
              setLoanAmountErrorLogic
            )
          }
          value={selectedProviderId}
        />
        <Text>Select Tenure</Text>
        <SingleSelect
          defaultSelected={"Select"}
          menuItems={getTenureOptions(tenureOptions)}
          handleChange={(event) =>
            handleTenureChange(
              event,
              setSelectedTenure,
              setLoanApplicationNumber,
              setDownpayment,
              setMonthlyEMI,
              setLoanAmountErrorLogic
            )
          }
          value={selectedTenure}
          disabled={!selectedProviderId}
        />
        {selectedTenure && (
          <div>
            <Text>Loan Application Amount</Text>
            <Textfield value={loanAmount} disabled={true} />
          </div>
        )}
        {loanAmount > 0 &&
        selectedTenure !== null &&
        isLoanAmountInValidRange(loanAmount, selectedTenure) &&
        isEmpty(loanAmountErrorLogic) ? (
          <>
            <Text>Loan Amount: {reduceToFixedDecimals(pendingAmount)} Rs</Text>
            <Text>
              Downpayment Amount:{" "}
              {reduceToFixedDecimals(totalAmount - pendingAmount)} Rs
            </Text>
            <Text>
              Downpayment Percentage:{" "}
              {reduceToFixedDecimals(selectedTenure.downpayment_in_percentage)}%
            </Text>
            <Text>Monthly EMI: {reduceToFixedDecimals(monthlyEMI)} Rs</Text>
            <Text>Loan Application Number: </Text>
            <Textfield
              value={loanApplicationNumber}
              handleOnChange={(event) =>
                setLoanApplicationNumber(event.target.value)
              }
            />
            {loanApplicationNumber.trim() === "" && (
              <ErrorMessage>Loan Application Number is required.</ErrorMessage>
            )}
            <PrimaryButton
              onClick={() =>
                handlePunchPayment(
                  selected_order_id,
                  pendingAmount,
                  selectedProviderId,
                  selectedTenure,
                  loanApplicationNumber,
                  punchPayment,
                  setDialogContent,
                  setDialogOpen
                )
              }
              disabled={
                !isFormValid(
                  selectedProviderId,
                  selectedTenure,
                  loanApplicationNumber
                )
              }
            >
              {isPunchPaymentLoading ? <Loader /> : "Punch Payment"}
            </PrimaryButton>
            {isError && <ErrorMessage>{error.message}</ErrorMessage>}
          </>
        ) : (
          loanAmountErrorLogic && (
            <div>
              <ErrorMessage>{loanAmountErrorLogic}</ErrorMessage>
              {remainingDownPaymentAmountForProvider > 0 &&
                !isPaymentMoreThanDownPayment && (
                  <OnlinePayment
                    orderId={selected_order_id}
                    payableAmount={remainingDownPaymentAmountForProvider}
                    isDownpayment={true}
                  />
                )}
            </div>
          )
        )}
      </div>
      <Dialog
        open={dialogOpen}
        handleClose={() => handleDialogClose(setDialogOpen)}
        title="Payment Status"
        height="auto"
      >
        {dialogContent}
      </Dialog>
    </>
  );
};

export default LoanPayment;
