import React from "react";
import PhoneInput from "react-phone-input-2";
import { styled } from "@mui/system";
import "react-phone-input-2/lib/style.css";

const StyledPhoneNumberInput = styled(PhoneInput)(() => ({
  margin: "10px",
}));

const PhoneNumberInput = ({
  value,
  onChange,
  country = "in",
  onlyCountries,
  ...rest
}) => {
  const handleChange = (value, countryData) => {
    onChange(value, countryData.dialCode);
  };

  return (
    <StyledPhoneNumberInput
      country={country}
      value={value}
      onChange={handleChange}
      onlyCountries={onlyCountries}
      {...rest}
    />
  );
};

export default PhoneNumberInput;
