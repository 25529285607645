import React from "react";
import MUIStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/system";
import SecondaryButton from "widgets/Buttons/SecondaryButton";

const StyledStepper = styled("div")(() => ({
  width: "100%",
  margin: "8px 0px",
}));

const Stepper = ({ children, activeStep }) => {
  const stepperChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === Stepper.Steps) {
      return React.cloneElement(child, {
        activeStep,
      });
    }
    return child;
  });

  return <StyledStepper>{stepperChildren}</StyledStepper>;
};

Stepper.Steps = ({ steps, activeStep }) => (
  <MUIStepper activeStep={activeStep} alternativeLabel>
    {steps.map((step) => (
      <Step key={steps.key}>
        <StepLabel>{step.label}</StepLabel>
      </Step>
    ))}
  </MUIStepper>
);

Stepper.PreviousButton = ({ onClick }) => (
  <SecondaryButton onClick={onClick}>PREV</SecondaryButton>
);

Stepper.NextButton = ({ onClick }) => (
  <SecondaryButton onClick={onClick}>NEXT</SecondaryButton>
);

export default Stepper;
