const getGlobalStyles = () => ({
  html: {
    height: "100%",
    width: "100%",
  },

  body: {
    fontFamily: "Montserrat",
    height: "100%",
    width: "100%",
    margin: "0%",
  },

  ":root": {
    "--color-hex": "#1E1E1E",
  },
});

export default getGlobalStyles;
