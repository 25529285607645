import React, { createContext, useState, useContext } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const SnackbarContext = createContext()

const SnackbarProvider = ({ children }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('success')

    const openSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity || 'success')
        setSnackbarOpen(true)
    }

    const closeSnackbar = () => {
        setSnackbarOpen(false)
    }

    return (
        <SnackbarContext.Provider value={{
            openSnackbar, closeSnackbar
        }}
        >
            {children}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top', horizontal: 'center'
                }}
                open={snackbarOpen}
                onClose={closeSnackbar}
                autoHideDuration={3000}
            >
                <SnackbarAlert onClose={closeSnackbar} severity={snackbarSeverity || 'success'}>
                    {snackbarMessage}
                </SnackbarAlert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}

const useSnackbarContext = () => {
    const context = useContext(SnackbarContext)
    if (!context) {
        throw new Error('useSnackbarContext must be used within a SnackbarProvider')
    }
    return context
}

const SnackbarAlert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
))

export { useSnackbarContext }
export default SnackbarProvider
