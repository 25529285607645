import React from "react";

import { CheckBox } from "widgets";
import AddressFields from "./AddressFields";

import styles from "./AddressForm.module.scss";

const AddressForm = (props) => {
  const {
    billingAddress,
    shippingAddress,
    handleChangeBillingAddress,
    handleChangeShippingAddress,
    useSameAddress,
    hasHardware,
    handleUseSameAddress,
    handleBlur,
    errors,
  } = props;

  return (
    <div className={styles.addressContainer}>
      <div className={styles.addressBox}>
        <div className={styles.addressTitle}>Add Billing Details</div>
        <AddressFields
          address={billingAddress}
          handleChangeFields={handleChangeBillingAddress}
          errors={errors.orderDetails}
          handleBlur={handleBlur}
          addressType="billing"
        />
      </div>
      {hasHardware && (
        <div className={styles.addressBox}>
          <CheckBox
            title="Use same address for shipping"
            checked={useSameAddress}
            handleCheck={handleUseSameAddress}
          />
          <div className={styles.addressTitle}>Add Shipping Details</div>
          <AddressFields
            address={useSameAddress ? billingAddress : shippingAddress}
            handleChangeFields={handleChangeShippingAddress}
            errors={errors.orderDetails}
            disabled={useSameAddress}
            handleBlur={handleBlur}
            addressType="shipping"
          />
        </div>
      )}
    </div>
  );
};

export default AddressForm;
