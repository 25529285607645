import React from "react";
import isEmpty from "lodash/isEmpty";

import DeleteIcon from "Shared/Icons/DeleteIcon";
import { Text } from "widgets";

import styles from "./SelectedPackageSubscriptions.module.scss";
import { INR_SYMBOL } from "constants/config";

const handleDeletePackage = (
  subscriptionId,
  selectedPackageSubscriptions,
  handleChangePackageSubscriptionSelection,
) => {
  const updatedPackages = selectedPackageSubscriptions.filter(
    (packageSubscription) => packageSubscription.id !== subscriptionId,
  );
  handleChangePackageSubscriptionSelection(updatedPackages);
};

const renderSelectedPackage = (
  packageSubscription,
  selectedPackageSubscriptions,
  handleChangePackageSubscriptionSelection,
) => {
  return (
    <div key={packageSubscription.id} className={styles.orderSummaryBox}>
      <div className={styles.deleteIcon}>
        <DeleteIcon
          onClick={() =>
            handleDeletePackage(
              packageSubscription.id,
              selectedPackageSubscriptions,
              handleChangePackageSubscriptionSelection,
            )
          }
        />
      </div>
      <div className={styles.label}>
        <span className={styles.detailTitle}>Package Name:</span>
        <span>{packageSubscription.displayName}</span>
      </div>
      <div className={styles.label}>
        <span className={styles.detailTitle}>Plan Name:</span>
        <span>{packageSubscription.planName}</span>
      </div>
      <div className={styles.label}>
        <span className={styles.detailTitle}>Validity:</span>
        <span>{packageSubscription.planValidity}</span>
      </div>
      <div className={styles.mrp}>
        <span className={styles.detailTitle}>MRP:</span>
        <span>{INR_SYMBOL + packageSubscription.mrp}</span>
      </div>
    </div>
  );
};

const SelectedPackageSubscriptions = (props) => {
  const {
    selectedPackageSubscriptions,
    handleChangePackageSubscriptionSelection,
  } = props;

  return (
    <div className={styles.orderSummaryContainer}>
      <Text>Selected Package Subscriptions</Text>
      {isEmpty(selectedPackageSubscriptions) ? (
        <p>No packages selected.</p>
      ) : (
        selectedPackageSubscriptions.map((packageSubscription) =>
          renderSelectedPackage(
            packageSubscription,
            selectedPackageSubscriptions,
            handleChangePackageSubscriptionSelection,
          ),
        )
      )}
    </div>
  );
};

export default SelectedPackageSubscriptions;
