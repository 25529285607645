import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from 'widgets'

const handleOnClickHomePageButton=(navigate)=>{
    navigate("/homePage")
}

const PageNotFound=()=> {
    const navigate = useNavigate()
    return (
        <div>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <PrimaryButton onClick={()=>handleOnClickHomePageButton(navigate)}>Home Page</PrimaryButton>
        </div>
    )
}

export default PageNotFound
