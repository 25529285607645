import React, { useState } from "react";

import { ErrorMessage, PrimaryButton, SecondaryButton, Text } from "widgets";
import { useDiscardOrder } from "store/backend/assets";

import styles from "./DiscardOrder.module.scss";

const handleOnClickDiscard = (
  setIsDiscarding,
  onClose,
  discardOrder,
  orderId,
) => {
  setIsDiscarding(true);

  discardOrder(orderId, {
    onSuccess: () => {
      onClose();
      setIsDiscarding(false);
      window.location.reload();
    },
    onError: (error) => {
      console.error("Error discarding the order:", error);
      setIsDiscarding(false);
    },
  });
};

const DiscardOrder = (props) => {
  const { onClose, orderId } = props;
  const [isDiscarding, setIsDiscarding] = useState(false);
  const {
    mutate: discardOrder,
    isPending: isDiscardOrderPending,
    error,
    isError,
  } = useDiscardOrder(orderId);

  return (
    <div className={styles.popOverContainer}>
      <Text>Are you sure you want to discard order?</Text>
      <div className={styles.flexContainer}>
        <SecondaryButton onClick={onClose}>Go Back</SecondaryButton>
        <PrimaryButton
          onClick={() =>
            handleOnClickDiscard(
              setIsDiscarding,
              onClose,
              discardOrder,
              orderId,
            )
          }
          discardOrder
          disabled={isDiscarding}
        >
          {isDiscardOrderPending ? "Discarding..." : "Discard"}
        </PrimaryButton>
      </div>
      {isError && (
        <div className={styles.errorMessage}>
          <ErrorMessage>
            {error?.message || "An error occurred while discarding the order."}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};
export default DiscardOrder;
