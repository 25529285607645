import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  DetailDisplay,
  Heading1,
  PhoneNumberInput,
  PrimaryButton,
  Text,
} from "widgets";
import { useFetchPendingOrders } from "store/backend/assets";
import Loader from "Shared/Loader/Loader";

import styles from "./HomePage.module.scss";
import { reduceToFixedDecimals } from "utils/amountCalculation";

const isViewCustomerDetailsButtonEnabled = (phoneNumber) => {
  const digits = phoneNumber.replace(/\D/g, "");
  return digits.length === 12;
};

const handlePhoneNumberChange = (
  phoneNumber,
  setPhoneNumber,
  setCountryCode
) => {
  const countryCode = phoneNumber.match(/^\+(\d+)/)?.[1] || "";
  const phoneNumberWithoutCountryCode = phoneNumber
    .replace(`+${countryCode}`, "")
    .trim();

  setPhoneNumber(phoneNumberWithoutCountryCode);
  setCountryCode(countryCode);
};

const handleOnClickPendingOrder = (uam_id, navigate) => {
  navigate(`/customerOrders?uam_id=${uam_id}`);
};

const handleClickViewCustomerDetailsButton = (phoneNumber, navigate) => {
  const cleanedPhoneNumber = phoneNumber.replace(/^(\d{2})(\d{10})$/, '$2');
  navigate(`/customerOrders?phone_number=${cleanedPhoneNumber}`);
};

const getPendingAmount = (pendingOrder) => {
  if (!pendingOrder) return null;
  const pendingOrderAmount =
    pendingOrder.inr_conversion_amount -
    pendingOrder.inr_conversion_amount_paid;
  return reduceToFixedDecimals(pendingOrderAmount);
};

const HomePage = () => {
  const navigate = useNavigate();
  const [sales_person_id] = useState(localStorage.getItem("sales_person_id"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  
  const {
    isPending: isPendingOrderLoading,
    data: pendingOrders,
    error: pendingOrdersError,
  } = useFetchPendingOrders(sales_person_id);

  if (pendingOrdersError) {
    return <span>Error fetching Pending Orders</span>;
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.cardContainer}>
        <Heading1 heading="Pending Orders" />
        <hr className={styles.horizontalLine} />
        <div className={styles.cardList}>
          {isPendingOrderLoading ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : pendingOrders && pendingOrders.length > 0 ? (
            pendingOrders.map((pendingOrder) => (
              <Card
                key={pendingOrder.id}
                height="9rem"
                className={styles.card}
                onClick={() =>
                  handleOnClickPendingOrder(pendingOrder.customer_id, navigate)
                }
              >
              <DetailDisplay
                  title="Customer Id"
                  value={pendingOrder.customer_id}
                />
                <DetailDisplay
                  title="Billing Name"
                  value={pendingOrder.recipient_name}
                />
                <DetailDisplay
                  title=" Billing Phone Number"
                  value={pendingOrder.masked_phone_no}
                />
                <DetailDisplay
                  title="Pending Amount"
                  value={getPendingAmount(pendingOrder)}
                />
                <DetailDisplay
                  title="Order Punch Date"
                  value={new Date(
                    pendingOrder.order_created_date
                  ).toLocaleDateString()}
                />
              </Card>
            ))
          ) : (
            <span>No Pending Orders</span>
          )}
        </div>
      </div>
      <div className={styles.phoneNumberInputContainer}>
        <Text>Please enter a phone number.</Text>
        <div className={styles.phoneNumberInputFlexBox}>
          <PhoneNumberInput
            value={phoneNumber}
            onChange={(event) =>
              handlePhoneNumberChange(event, setPhoneNumber, setCountryCode)
            }
            onlyCountries={["in"]}
          />
        </div>
        <PrimaryButton
          onClick={() =>
            handleClickViewCustomerDetailsButton(phoneNumber, navigate)
          }
          disabled={
            !phoneNumber || !isViewCustomerDetailsButtonEnabled(phoneNumber)
          }
        >
          View Customer Details
        </PrimaryButton>
      </div>
    </div>
  );
};

export default HomePage;
