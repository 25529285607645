import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";

const CustomMenuItem = styled(MenuItem)(() => ({
  "&&": {
    display: "flex",
    padding: "8px",
    boxSizing: "border-box",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default CustomMenuItem;
