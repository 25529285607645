import React from "react";
import { styled } from "@mui/system";

const StyledErrorText = styled("span")(() => ({
  color: "#da2e23",
  display: "flex",
  paddingBottom: "10px",
  justifyContent: "flex-start",
  alignItems: "left",
}));

const ErrorMessage = ({ children }) => (
  <StyledErrorText>{children}</StyledErrorText>
);

export default ErrorMessage;
