import React from "react";
import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";

const StyledDialogContent = styled(DialogContent)(({ height,width }) => ({
  height: height || "150px",
  overflow: "hidden",
  margin: "5px",
  width: width || "400px",
}));

const Dialog = ({ open, handleClose, title, children, height }) => {
  return (
    <MuiDialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" sx={{ padding: "12px" }}>
        {title}
      </DialogTitle>
      <StyledDialogContent height={height}>{children}</StyledDialogContent>
    </MuiDialog>
  );
};

export default Dialog;
