import Select from "@mui/material/Select";
import { styled } from "@mui/system";

const StyledSelect = styled(Select)(() => ({
  "&&": {
    width: "100%",
    height: "48px",
    color: "#000000",
    background: "#ffffff",
    borderRadius: "5px",
  },
}));

export default StyledSelect;
