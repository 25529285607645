import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";

const StyledTextField = styled(TextField)({
  display: "flex",
  alignItems: "left-align",
  padding: "5px",
  width: "100%",
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      padding: "10px",
      color: "black",
      width: "100%",
      backgroundColor: "#ffffff",
    },
  },
});

const Textfield = (props) => {
  const {
    id,
    name,
    label: placeholder,
    type,
    variant,
    value,
    handleOnChange: onChange,
    onBlur,
    error,
    helperText,
    disabled,
  } = props;
  const fieldProps = {
    id,
    name,
    placeholder,
    type,
    variant,
    value,
    onChange,
    error,
    helperText,
    onBlur,
    disabled,
  };
  return <StyledTextField {...fieldProps} />;
};

export default Textfield;
