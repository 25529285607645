import { styled } from "@mui/system";
import Button from "@mui/material/Button";

const SecondaryButton = styled(Button)(
  () => `
    && {
        background-color: #ffffff;
        color: #1e50c4;
        border: 3px solid #1e50c4;
        &:hover {
            background-color: #dedede;
        },
        &:disabled {
            background-color: #bdbdbd;
            color: #757575
        }
        border-radius: 4px;
        box-shadow: none;
        text-transform: capitalize;
        min-width: 6rem;
        width: fit-content;
    }
`,
);

export default SecondaryButton;
