import * as React from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const StyledSearchField = styled(TextField)(() => ({
  "&&": {
    width: "100%",
    background: "#ffffff",
    border: "0.7px solid #b3b3b3",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
      color: "#000000",
    },
    "& .MuiInputLabel-root": {
      color: "#000000",
    },
  },
}));

const SearchField = (props) => (
  <StyledSearchField
    {...props}
    InputProps={{
      startAdornment: (
        <SearchIcon
          style={{
            marginRight: "8px",
            color: "#b3b3b3",
          }}
        />
      ),
    }}
  />
);

export default SearchField;
