import * as React from "react";

const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={44.5} height={39.5} x={0.25} y={0.25} fill="#fff" rx={4.75} />
    <path
      fill="#F04344"
      d="M13.692 14.154h1.847V28a3 3 0 0 0 3 3h9.23a3 3 0 0 0 3-3V14.154h1.385a.693.693 0 0 0 0-1.385h-4.846v-.23A2.539 2.539 0 0 0 24.769 10h-3.23A2.539 2.539 0 0 0 19 12.539v.23h-5.308a.692.692 0 0 0 0 1.385ZM29.385 28a1.615 1.615 0 0 1-1.616 1.616h-9.23A1.616 1.616 0 0 1 16.922 28V14.154h12.462V28Zm-9-15.461a1.155 1.155 0 0 1 1.153-1.154h3.231a1.153 1.153 0 0 1 1.154 1.154v.23h-5.538v-.23Z"
    />
    <path
      fill="#F04344"
      d="M23.154 18.076a.692.692 0 0 0-.692.692v8.308a.693.693 0 0 0 1.384 0v-8.308a.692.692 0 0 0-.692-.692ZM25.693 18.768v8.308a.692.692 0 0 0 1.385 0v-8.308a.692.692 0 0 0-1.385 0ZM19.923 18.076a.692.692 0 0 0-.692.692v8.308a.692.692 0 0 0 1.385 0v-8.308a.692.692 0 0 0-.693-.692Z"
    />
  </svg>
);

export default DeleteIcon;
