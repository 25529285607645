import React from "react";
import {  } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLogin } from "../../store/backend/useLogin";
import useAuth from "../../hooks/useAuth";
import Loader from "../../Shared/Loader/Loader";
import { ErrorMessage, Heading1, PrimaryButton, Textfield } from "widgets";

import styles from "./Login.module.scss";

const handleOnSuccessLogin = (
  data,
  authLogin,
  navigate,
  setPhoneNumber,
  setAdmissionNumber
) => {
  authLogin(data.accessToken);
  if (setPhoneNumber) setPhoneNumber(data.phoneNumber);
  if (setAdmissionNumber) setAdmissionNumber(data.admission_number);
  localStorage.setItem("phoneNumber", data.phoneNumber);
  localStorage.setItem("admissionNumber", data.admission_number);
  localStorage.setItem("sales_person_id", data.crnId);
  localStorage.setItem("roles", data.roles);
  navigate("/homePage");
};

const Login = ({ setPhoneNumber, setAdmissionNumber }) => {
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const {
    mutate: login,
    isPending,
    isError,
    error,
  } = useLogin((data) =>
    handleOnSuccessLogin(
      data,
      authLogin,
      navigate,
      setPhoneNumber,
      setAdmissionNumber
    )
  );

  return (
      <Formik
      initialValues={{
        mobile: "",
        password: "",
      }}
      validationSchema={Yup.object({
        mobile: Yup.string()
          .required("Mobile number is required")
          .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
        password: Yup.string()
          .required("Password is required")
          .min(6, "Password must be at least 6 characters"),
      })}
      onSubmit={(values) => {
        login(values);
      }}
    >
      {({ values, setFieldValue, handleSubmit, isValid, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <div className={styles.loginFormContainer}>
            <Heading1 heading="Login" />
            <div>
              <Textfield
                id="mobile"
                label="Mobile"
                type="text"
                value={values.mobile}
                handleOnChange={(event) =>
                  setFieldValue("mobile", event.target.value)
                }
              />
              {errors.mobile && <ErrorMessage>{errors.mobile}</ErrorMessage>}
              </div>
              <div>
                <Textfield
                  id="password"
                  label="Password"
                  type="password"
                  value={values.password}
                  handleOnChange={(event) =>
                    setFieldValue("password", event.target.value)
                  }
                />
                {errors.mobile && <ErrorMessage>{errors.password}</ErrorMessage>}
              </div>
              {isError && (
                <ErrorMessage className={styles.errorMessage}>
                  {error.message || "Unable to log in"}
                </ErrorMessage>
              )}
            <div className={styles.btnContainer}>
              <PrimaryButton type="submit" disabled={isPending || !isValid}>
                {isPending? <Loader /> : "Login"}
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>    
  );
};

export default Login;
