import { styled } from "@mui/system";
import Button from "@mui/material/Button";

const ErrorButton = styled(Button)(
  () => `
    && {
        background-color: #ffffff;
        color: #da2e23;
        border: 1px solid #da2e23;
        &:hover {
            background-color: #dedede;
        },
        &:disabled {
            background-color: #bdbdbd;
            color: #757575
        }
        border-radius: 4px;
        box-shadow: none;
        text-transform: capitalize;
        min-width: 6rem;
        width: fit-content;
    }
`,
);

export default ErrorButton;
