import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import * as tokenUtils from "utils/tokenUtils";
import routes from "constants/routes";
import Header from "../components/Header/Header";
const AuthenticatedRoute = () => {
  const loggedIn = tokenUtils.isValidUser();

  if (!loggedIn) {
    return (
     <>
      <Header/>
      <Navigate
        to={`${routes.login.root}/?redirect=${window.location.pathname}`}
        replace
      />
      </>
    );
  }

  return <Outlet />;
};

export default AuthenticatedRoute;
