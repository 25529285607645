import React, { useState } from "react";

import {
  DetailDisplay,
  Dialog,
  ErrorButton,
  ErrorMessage,
  PrimaryButton,
  Table,
  Text,
} from "widgets";

import {
  INR_SYMBOL,
  ORDER_STATUSES,
  PACKAGE_DETAILS_COLUMN,
  PAYMENT_DETAILS_COLUMN,
  PAYMENT_STATUSES,
  PAYMENTS_ATTEMPTS_COLUMN,
  PENDING_ORDER_STATUS,
  REFUND_DETAILS_COLUMN,
  SUCCESSFUL_PAYMENT_STATUSES,
} from "constants/config";
import DiscardOrder from "components/DiscardOrder/DiscardOrder";
import CancelOrder from "components/CancelOrder/CancelOrder";
import {
  useFetchOrderSummaryPDF,
  useFetchPaymentReceipt,
  useFetchTaxInvoicePDF,
} from "store/backend/assets";
import Loader from "Shared/Loader/Loader";
import { useSnackbarContext } from "widgets/SnackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

import styles from "./CustomerOrderDetails.module.scss";

const getFormattedPackageDetails = (orderDetail) => {
  if (!orderDetail || !Array.isArray(orderDetail.packages)) {
    return [];
  }
  return orderDetail.packages.flatMap((pkg) => {
    const subscriptions = pkg.subscriptions || [];
    return subscriptions.map((sub) => {
      const grade = pkg.package_grades?.[0]?.grade_name || "N/A";
      return {
        name: pkg.display_name || "N/A",
        price: INR_SYMBOL + (sub.mrp || 0),
        discount:
          INR_SYMBOL +
          (sub.discount || []).reduce((total, d) => total + d.amount, 0),
        duration: sub.no_of_days || 0,
        grade: grade,
      };
    });
  });
};

const getFormattedRefundDetails = (refundDetails) => {
  if (!refundDetails || !Array.isArray(refundDetails)) {
    return [];
  }
  return refundDetails.map((refundDetail) => {
    return {
      amount: refundDetail.inr_conversion,
      status: refundDetail.status?.name,
      refundDate: new Date(refundDetail.refund_date).toLocaleString(),
      refundReason: refundDetail.reason.name,
    };
  });
};

const handleOnClickDownloadPaymentReceipt = (
  paymentId,
  fetchPaymentReceipt,
  openSnackbar
) => {
  if (paymentId) {
    fetchPaymentReceipt(paymentId)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.signedUrl;
        link.click();
        openSnackbar("Payment receipt downloaded successfully!", "success");
      })
      .catch((error) => {
        console.error("Error fetching receipt", error);
        openSnackbar(
          error.message || "Error fetching payment receipt",
          "error"
        );
      });
  } else {
    console.error("No payment ID provided");
  }
};
const getFormattedPaymentDetails = (
  selectedOrder,
  fetchPaymentReceipt,
  isFetchPaymentReceiptLoading,
  openSnackbar
) => {
  if (!selectedOrder || !Array.isArray(selectedOrder.payment_details))
    return [];

  return selectedOrder.payment_details
    .sort((p1, p2) => new Date(p2.date) - new Date(p1.date))
    .map((paymentDetail) => {
      const paymentStatus = paymentDetail.status.id;

      return {
        amount: INR_SYMBOL + paymentDetail.amount,
        status: paymentDetail.status.name,
        paymentDate: new Date(paymentDetail.date).toLocaleString(),
        paymentMethod: paymentDetail.method.name,
        receipt: [
          PAYMENT_STATUSES.APPROVED_BY_PROVIDER,
          PAYMENT_STATUSES.DISBURSED,
        ].includes(paymentStatus) ? (
          <PrimaryButton
            onClick={() =>
              handleOnClickDownloadPaymentReceipt(
                paymentDetail.id,
                fetchPaymentReceipt,
                openSnackbar
              )
            }
            disabled={isFetchPaymentReceiptLoading}
          >
            {isFetchPaymentReceiptLoading ? <Loader /> : "Download"}
          </PrimaryButton>
        ) : (
          "N/A"
        ),
        history: Array.isArray(
          paymentDetail.transaction_details?.payment_attempts
        )
          ? paymentDetail.transaction_details.payment_attempts.map(
              (attempt) => ({
                date: new Date(attempt.date).toLocaleString(),
                method: attempt.method?.name || "N/A",
                status: attempt.status?.name || "N/A",
                reason: attempt.reason?.name || "N/A",
                description: attempt.error?.description ? (
                  <Tooltip title={attempt.error.description} arrow>
                    <span>
                      {attempt.error.description.length > 50
                        ? `${attempt.error.description.slice(0, 50)}...`
                        : attempt.error.description}
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                ),
              })
            )
          : [],
      };
    });
};

const handleClickGetOrderSummaryPDFButton = (
  orderId,
  refetchOrderSummaryPDF,
  openSnackbar
) => {
  if (!!orderId) {
    refetchOrderSummaryPDF()
      .then((response) => {
        if (response && response.status === "success") {
          const order_summary_response = response.data;
          if (!!order_summary_response.message) {
            openSnackbar(order_summary_response.message);
          }
          if (!!order_summary_response.signed_url) {
            const link = document.createElement("a");
            link.href = order_summary_response.signed_url;
            link.download = `order_summary_${orderId}.pdf`;
            link.click();
            openSnackbar("Order summary downloaded successfully!", "success");
          }
        } else {
          openSnackbar(
            response.error.message || "Error fetching Order Summary"
          );
        }
      })
      .catch((error) => {
        openSnackbar("Error fetching Order Summary");
      });
  }
};

const handleClickGetTaxInvoiceButton = (
  orderId,
  fetchTaxInvoicePDF,
  openSnackbar
) => {
  if (orderId) {
    fetchTaxInvoicePDF(orderId)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.signedUrl;
        link.click();
        openSnackbar("Tax Invoice downloaded successfully!", "success");
      })
      .catch((error) => {
        console.error("Error fetching tax invoice", error);
        openSnackbar(error.message || "Error fetching tax invoice", "error");
      });
  } else {
    console.error("No order ID provided");
  }
};
const handleClickProceedToPaymentButton = (
  phone_number,
  uam_id,
  selectedOrderID
) => {
  if (uam_id) {
    const newUrl = `/paymentPreference?uam_id=${uam_id}&order_id=${selectedOrderID}`;
    window.location.href = newUrl;
    return;
  }
  const newUrl = `/paymentPreference?phone_number=${phone_number}&order_id=${selectedOrderID}`;
  window.location.href = newUrl;
  return;
};

const handleDialogClose = (setOpenDialogContent) => () => {
  setOpenDialogContent(null);
};

const handleDialogOpen = (setOpenDialogContent, content) => () => {
  setOpenDialogContent(content);
};

const handleClickTransferOrder = (navigate, uam_id, selectedOrder) => {
  navigate(`/punchOrder?uam_id=${uam_id}`, {
    state: { transferOrderDetails: selectedOrder },
  });
};

const canDiscardOrder = (selectedOrder) => selectedOrder.amount_paid === 0;

const canRequestOrderCancellation = (selectedOrder) => {
  if (
    selectedOrder &&
    selectedOrder.payment_details &&
    selectedOrder.payment_details.length > 0
  ) {
    const paymentStatusIds = selectedOrder.payment_details.map(
      (paymentDetail) => paymentDetail.status.id
    );

    const hasSuccessfulPayment = paymentStatusIds.some((statusId) =>
      SUCCESSFUL_PAYMENT_STATUSES.includes(statusId)
    );

    return hasSuccessfulPayment;
  }
  return false;
};

const canRequestTransferOrder = (orderDetails, selectedOrder) => {
  if (orderDetails && orderDetails.length === 0) {
    return true;
  }

  const getPendingOrders = (orderDetails) => {
    return orderDetails.filter((order) =>
      PENDING_ORDER_STATUS.includes(order.status.id)
    );
  };
  const pendingOrders = getPendingOrders(orderDetails);

  if (pendingOrders.length === 0) {
    return true;
  }

  if (pendingOrders > 1) {
    return false;
  }

  if (pendingOrders[0].order_id !== selectedOrder.order_id) {
    return false;
  }

  return true;
};

const getDialogHeight = (orderStatus) => {
  if (!orderStatus) return null;
  return orderStatus === ORDER_STATUSES.ACCEPTED ? "130px" : "130px";
};

const hasPaymentAttemptDetails = (paymentDetail) => {
  return (
    paymentDetail.transaction_details &&
    Array.isArray(paymentDetail.transaction_details.payment_attempts) &&
    paymentDetail.transaction_details.payment_attempts.length > 0
  );
};
const CustomerOrderDetails = (props) => {
  const {
    orderId,
    orderDetails,
    selectedOrder,
    setSelectedOrderID,
    setSelectedOrder,
    orderStatus,
    phone_number,
    uam_id,
  } = props;
  const { openSnackbar } = useSnackbarContext();
  const [openDialogContent, setOpenDialogContent] = useState(null);
  const navigate = useNavigate();

  const {
    isLoading: isOrderSummaryPDFLoading,
    refetch: refetchOrderSummaryPDF,
  } = useFetchOrderSummaryPDF(orderId);

  const { fetchTaxInvoicePDF, isLoading: isTaxInvoicePDFLoading } =
    useFetchTaxInvoicePDF();

  const { fetchPaymentReceipt, isLoading: isFetchPaymentReceiptLoading } =
    useFetchPaymentReceipt();

  const packageDetails = selectedOrder
    ? getFormattedPackageDetails(selectedOrder)
    : [];

  const paymentDetailsRows = selectedOrder
    ? getFormattedPaymentDetails(
        selectedOrder,
        fetchPaymentReceipt,
        isFetchPaymentReceiptLoading,
        openSnackbar
      )
    : [];

  const refundDetailsRows = selectedOrder
    ? getFormattedRefundDetails(selectedOrder.refund_details)
    : [];

  const shouldShowAccordion = selectedOrder?.payment_details?.some(
    (paymentDetail) => hasPaymentAttemptDetails(paymentDetail)
  );
  return (
    <div className={styles.customerOrderDetailsContainer}>
      <div className={styles.ordersList}>
        <Text>Customer Orders</Text>
        {orderDetails
          ?.sort(
            (o1, o2) =>
              new Date(o2.order_created_date) - new Date(o1.order_created_date)
          )
          .map((order) => (
            <div
              className={`${styles.orderBox} ${
                selectedOrder?.order_id === order.order_id
                  ? styles.selectedOrder
                  : ""
              }`}
              key={order.order_id}
              onClick={() => {
                setSelectedOrder(order);
                setSelectedOrderID(order.order_id);
              }}
            >
              <DetailDisplay
                title="Order Amount"
                value={INR_SYMBOL + order.amount}
              />
              <DetailDisplay
                title="Punch Date"
                value={new Date(order.order_created_date).toLocaleDateString()}
              />
              <DetailDisplay
                title="Status"
                value={
                  order.status.name === "FULFILLED"
                    ? "FULLY_PAID"
                    : order.status.name
                }
              />
            </div>
          ))}
      </div>
      <div className={styles.customerOrderDetails}>
        <div className={styles.buttonContainer}>
          {orderStatus === ORDER_STATUSES.ACCEPTED &&
            canDiscardOrder(selectedOrder) && (
              <ErrorButton
                onClick={handleDialogOpen(
                  setOpenDialogContent,
                  <DiscardOrder
                    onClose={handleDialogClose(setOpenDialogContent)}
                    orderId={selectedOrder.order_id}
                  />
                )}
              >
                Discard Order
              </ErrorButton>
            )}
          {orderStatus === ORDER_STATUSES.ACCEPTED &&
            canRequestOrderCancellation(selectedOrder) && (
              <ErrorButton
                onClick={handleDialogOpen(
                  setOpenDialogContent,
                  <CancelOrder
                    orderId={selectedOrder.order_id}
                    onClose={handleDialogClose(setOpenDialogContent)}
                  />
                )}
              >
                Request Cancel Order
              </ErrorButton>
            )}
          <PrimaryButton
            disabled={isOrderSummaryPDFLoading}
            onClick={() =>
              handleClickGetOrderSummaryPDFButton(
                selectedOrder.order_id,
                refetchOrderSummaryPDF,
                openSnackbar
              )
            }
          >
            Get Order Summary
          </PrimaryButton>
          {orderStatus === ORDER_STATUSES.FULFILLED && (
            <PrimaryButton
              disabled={isTaxInvoicePDFLoading}
              onClick={() =>
                handleClickGetTaxInvoiceButton(
                  selectedOrder.order_id,
                  fetchTaxInvoicePDF,
                  openSnackbar
                )
              }
            >
              Get Tax Invoice
            </PrimaryButton>
          )}
          {(orderStatus === ORDER_STATUSES.ACCEPTED ||
            orderStatus === ORDER_STATUSES.FULFILLED) &&
            canRequestTransferOrder(orderDetails, selectedOrder) && (
              <PrimaryButton
                onClick={() =>
                  handleClickTransferOrder(navigate, uam_id, selectedOrder)
                }
              >
                Request Modification
              </PrimaryButton>
            )}
        </div>
        <div className={styles.customerOrderDetailsTableContainer}>
          <div className={styles.packageDetail}>
            <Table columns={PACKAGE_DETAILS_COLUMN} rows={packageDetails} />
            {selectedOrder && (
              <div className={styles.amountDetails}>
                <div className={styles.detailItem}>
                  <span className={styles.title}>Total Amount:</span>
                  <span>{INR_SYMBOL + selectedOrder.listed_order_amount}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.title}>Paid:</span>
                  <span>{INR_SYMBOL + selectedOrder.amount_paid}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.title}>Total Savings:</span>
                  <span>
                    {INR_SYMBOL + selectedOrder.total_inr_discount_amount}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={styles.amountDetailsTable}>
            <div className={styles.proceedToCollectPaymentButton}>
              {orderStatus === ORDER_STATUSES.ACCEPTED && (
                <PrimaryButton
                  onClick={() =>
                    handleClickProceedToPaymentButton(
                      phone_number,
                      uam_id,
                      selectedOrder.order_id
                    )
                  }
                >
                  Proceed to Collect Payment
                </PrimaryButton>
              )}
            </div>
            {paymentDetailsRows.length > 0 ? (
              <Table
                columns={PAYMENT_DETAILS_COLUMN}
                rows={paymentDetailsRows}
                accordion={shouldShowAccordion}
                accordionTableColumns={PAYMENTS_ATTEMPTS_COLUMN}
              />
            ) : (
              <ErrorMessage>No payments done.</ErrorMessage>
            )}
          </div>
          <div className={styles.amountDetailsTable}>
            {refundDetailsRows.length > 0 ? (
              <Table columns={REFUND_DETAILS_COLUMN} rows={refundDetailsRows} />
            ) : null}
          </div>
        </div>
      </div>
      <Dialog
        open={openDialogContent !== null}
        handleClose={handleDialogClose(setOpenDialogContent)}
        height={getDialogHeight(orderStatus)}
      >
        {openDialogContent}
      </Dialog>
    </div>
  );
};
export default CustomerOrderDetails;
