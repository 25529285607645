import React from "react";

import { ErrorMessage, PrimaryButton, SecondaryButton, Text } from "widgets";
import Loader from "Shared/Loader/Loader";

import styles from "./RequestTransferOrder.module.scss";

const RequestTransferOrder = (props) => {
  const { onCancel, onConfirm, isPending, error ,isError} = props;

  return (
    <div className={styles.popOverContainer}>
      <Text>Are you sure you want to transfer the order ?</Text>
      <div className={styles.flexContainer}>
        <SecondaryButton onClick={onCancel}>Go Back</SecondaryButton>
        <PrimaryButton onClick={onConfirm}>
          {isPending ? <Loader /> : "Confirm and Proceed"}
        </PrimaryButton>
      </div>
      {isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : null}
    </div>
  );
};
export default RequestTransferOrder;
