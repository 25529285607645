import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

import ErrorFallback from "./components/ErrorFallBack/ErrorFallback";
import Header from "./components/Header/Header";
import Routes from "./Routes";
import { queryClient, persister } from "./store/serverStore";
import { logger } from "./utils/logger";
import getGlobalStyles from "./globalStyles";
import { SnackbarProvider } from "widgets";

const App = () => {
  const theme = createTheme();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logger}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={getGlobalStyles()} />
        <SnackbarProvider>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister }}
          >
            <BrowserRouter>
              <Header />
              <Routes />
            </BrowserRouter>
          </PersistQueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
