import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { ReactComponent as Logo } from "../../Shared/Icons/logo.svg";
import * as tokenUtils from "utils/tokenUtils";

import styles from "./Header.module.scss";

const handleOnClickLogoutButton = (token, navigate) => {
  Cookies.remove("jwtToken");
  localStorage.clear();
  navigate("/login");
};
const handleOnClickHomeButton = (loggedIn,navigate) => {
  if (loggedIn) {
    navigate("/homePage");
  }
};
const Header = () => {
  const navigate = useNavigate();
  const loggedIn = tokenUtils.isValidUser();
  const token = Cookies.get("jwtToken");

  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoAndHomeIconContainer}>
        <Logo onClick={() => handleOnClickHomeButton(loggedIn,navigate)} />
      </div>
      {loggedIn && (
        <div
          className={styles.logOutButton}
          onClick={() => handleOnClickLogoutButton(token, navigate)}
        >
          Logout
        </div>
      )}
    </div>
  );
};

export default Header;
